import Vue from 'vue'
import VueRouter from 'vue-router'
import ApiDocsView from '../views/ApiDocsView.vue'
import auth from '../auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ApiDocs',
    component: ApiDocsView,
    meta: {
      authName: auth.authName
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
