<template>
  <v-app>
    <v-app-bar
      app
    >
      <div class="d-flex align-center">
        <a
          target="_blank"
          href="//mymsa.com.au/"
        >
          <v-img
            src="../public/img/icons/icon-120x120.png"
            alt="MSA"
            class="shrink mr-2"
            contain
            transition="scale-transition"
            width="40"
          />
        </a>
        <a
          target="_blank"
          href="//www.mla.com.au/"
        >
          <v-img
            src="../public/img/icons/logo_mla_t.png"
            alt="MLA"
            class="shrink mr-2"
            contain
            transition="scale-transition"
            width="80"
          />
        </a>

        <span class="font-weight-medium d-none d-md-inline">
          myMSA Objective Measurement Metadata REST API Documentation
        </span>
      </div>

      <v-spacer></v-spacer>

      <span v-if="$route.meta" class="font-weight-medium">{{ $route.meta.title }}</span>

      <v-spacer></v-spacer>

      <template v-if="$auth.isAuthenticated">
        <div class="grow text-right mr-5">
          {{ $auth.userProfile.name }}
        </div>
        <div class="shrink mr-5">
          <v-select
            label="myMSA Environment"
            :items="serverOptions"
            v-model="serverUrl"
            width="500px"
            hide-details
            class="shrink mr-5"
          />
        </div>
        <v-btn
          color="primary"
          depressed
          @click="$auth.signOut()"
        >
          Log out
        </v-btn>
      </template>

      <v-btn
        v-else
        color="primary"
        depressed
        @click="signIn"
      >
        Log in
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view
        :server-url="serverUrl"
      />
    </v-main>

    <v-footer
      fixed
      app
      padless
      height="32"
    >
      <v-container>
        <v-row row wrap>
          <v-col justify="center" class="py-0 text-center">
            myMSA - &copy; {{ new Date().getFullYear() }} |
            <a
              target="_blank"
              href="//www.mla.com.au/General/Privacy"
              >MLA Privacy Policy</a
            >
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    serverUrl: 'https://om-api.mymsa.com.au',
    servers: {
      dev: { value: 'https://om-api.dev.mymsa.com.au', text: 'Development' },
      test: { value: 'https://om-api.test.mymsa.com.au', text: 'Test' },
      training: { value: 'https://om-api.training.mymsa.com.au', text: 'Training' },
      production: { value: 'https://om-api.mymsa.com.au', text: 'Production' }
    }
  }),

  computed: {
    serverOptions () {
      const serverOptions = [
        this.servers.production,
        this.servers.training
      ]
      this.$auth.isStaff() && serverOptions.push(this.servers.test)
      this.$auth.isAdmin() && serverOptions.push(this.servers.dev)

      return serverOptions
    }
  }
}
</script>
