import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export const theme = {
  primary: '#007549',
  secondary: '#FDB714',
  accent: '#4B4F54',
  error: '#FF5252',
  danger: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  'warning-text': '#721c24'
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme
    }
  },
  icons: {
    iconfont: 'md'
  }
})
