import Vue from 'vue'
import App from './App.vue'
import auth from './auth'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

auth.useRouter(router)

auth.startup()
  .then(async ok => {
    if (!ok) throw new Error('Failed to configure authentication.')

    Vue.prototype.$auth = auth // Make $auth accessible by all vue instances
    new Vue({
      vuetify,
      router,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(e => {
    console.error(e)
  })
