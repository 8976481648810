<template>
  <!-- see for props: https://github.com/Rhosys/openapi-explorer/blob/f329342d87eef2ae7c17f250170e1b08f587b67b/src/openapi-explorer.js#L394-->
  <openapi-explorer
    spec-url="./openapi/mymsa-om-api.yaml"
    :server-url="serverUrl"
    :show-server-selection="String(false)"
    :primary-color="theme.primary"
    :secondary-color="theme.secondary"
    nav-text-color="#fff"
    nav-hover-text-color="#fff"
  >
  </openapi-explorer>
</template>

<style>
open-api-explorer {
  --font-regular: 'Roboto', sans-serif;
  --font-mono: 'Roboto Mono', monospace;
}
</style>

<script>
import 'openapi-explorer'
import { theme } from '../plugins/vuetify'

export default {
  name: 'ApiDocsView',

  props: {
    serverUrl: {
      type: String,
      required: true
    }
  },

  data: () => ({
    theme
  })
}
</script>
