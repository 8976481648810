import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2'

export const ACCESS_TYPE = {
  ADMIN: 'admin',
  STAFF: 'staff',
  RESTRICTED: 'restricted'
}

const appUrl = new URL(window.location.origin)
const auth = createOidcAuth('main', SignInType.Window, appUrl, {
  authority: process.env.VUE_APP_OIDC_AUTHORITY,
  client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
  response_type: process.env.VUE_APP_OIDC_RESPONSE_TYPE ?? 'code',
  scope: 'openid profile email phone msa',
  loadUserInfo: false,
  automaticSilentRenew: true,
  post_logout_redirect_uri: null
})

auth.getAccessType = function () {
  return (auth.userProfile.access_type ?? '').toLowerCase()
}

auth.isStaffOrAdmin = function () {
  return auth.isStaff() || auth.isAdmin()
}

auth.isStaff = function () {
  if (!auth.isAuthenticated) return false
  return auth.getAccessType() === ACCESS_TYPE.STAFF
}

auth.isAdmin = function () {
  if (!auth.isAuthenticated) return false
  return auth.getAccessType() === ACCESS_TYPE.ADMIN
}

export default auth
